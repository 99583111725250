<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
    >{{message}}</vs-alert>
    <br />
    <form>
      <label for="email">Email</label>
      <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        v-model="user.email"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first('email') }}</span>
      <br />
      <label for="password">Password</label>
      <vs-input
        data-vv-validate-on="blur"
        v-validate="'required'"
        name="password"
        icon-no-border
        icon-pack="feather"
        v-model="user.password"
        class="w-full"
        :icon="passwordIcon"
        :type="passwordType"
        @click.native="showHidePassword($event)"
      ></vs-input>
      <span class="text-danger text-sm">{{ errors.first('password') }}</span>

      <br />
    </form>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="rememberMe" class="mb-3">Remember Me</vs-checkbox>
      <router-link to="/forgot-password">Forgot Password?</router-link>
    </div>

    <div class="flex flex-wrap justify-between mb-3">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
export default {
  data() {
    return {
      user: {
        email: $cookies.get(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Email`) || "",
        password: $cookies.get(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Password`) || "",
      },
      loginStatus: false,
      showDismissibleAlert: false,
      rememberMe: $cookies.get(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}RememberMe`) || false,
      showPassword: false,
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      message: "",
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    ...mapActions("users", ["login"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.showPassword = !this.showPassword;
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    loginJWT() {
      const payload = {
        rememberMe: JSON.parse(this.rememberMe),
        userDetails: this.user,
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store
            .dispatch("auth/loginJWT", payload)
            .then((response) => {
              this.$vs.loading.close();
              this.$router.push("/admin/dashboard");
            })
            .catch((error) => {
              this.showDismissibleAlert = true;
              this.message = error.response.data.message;
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: this.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        }
      });

      //   this.login(this.user).then((res)=>{
      //     if(res) {
      //       this.$router.push('/admin/dashboard')
      //     }
      // }).catch((err)=>{
      //     this.message=err.data.msg||err.data.message;
      //         this.$vs.notify({
      //           title: this.message,
      //           text: this.message,
      //           iconPack: 'feather',
      //           icon: 'icon-alert-circle',
      //           color: 'warning'
      //         })
      //
      // })
    },
    forgotPassword() {
      this.$router.push("forgotPassword");
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    // loginJWT() {
    //
    //   if (!this.checkLogin()) return
    //
    //   // Loading
    //   this.$vs.loading()
    //
    //   const payload = {
    //     checkbox_remember_me: this.checkbox_remember_me,
    //     userDetails: {
    //       email: this.email,
    //       password: this.password
    //     }
    //   }
    //
    //   this.$store.dispatch('auth/loginJWT', payload)
    //     .then(() => { this.$vs.loading.close() })
    //     .catch(error => {
    //       this.$vs.loading.close()
    //       this.$vs.notify({
    //         title: 'Error',
    //         text: error.message,
    //         iconPack: 'feather',
    //         icon: 'icon-alert-circle',
    //         color: 'danger'
    //       })
    //     })
    // },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/register").catch(() => {});
    },
  },
};
</script>

